import React from "react"
import {Row, Col} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from '../components/contact-form.js'
import "../styles/contact.css"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faMapMarker} from '@fortawesome/free-solid-svg-icons'

import joyStanding from '../images/contact/joy_standing.png'

const ContactPage = () => (
    <Layout pageInfo={{
        pageName: "contact-us"
    }}>
        <Seo
            title="Contact Us"
            keywords={[`equoogame`, `clinical research`, `mental health`]}/>
        <section id="map-section">
            <Row
                className="bg-white"
                style={{
                paddingLeft: '0',
                marginLeft: '0'
            }}></Row>
            <Row className="contact-map">
                <Col md="12" style={{
                    padding: "20px"
                }}>
                    <div id="map-image-section" class="z-depth-1-half map-container-6" style={{minHeight: "400px"}}>
                        {/* <iframe
                            title="PsycApps Ltd"
                            width="100%"
                            height="512px"
                            frameborder="0"
                            style={{
                            border: "0"
                        }}
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDOvtlMs5GcsNTMwVykwNfwlBEtvYb4HwI&q=Health+Foundry,Canterbury+House,London"
                            allowfullscreen></iframe> */}
                    </div>
                </Col>
            </Row>
        </section>
        <section id="contact-details-mobile">
            <Row>
            <Col
                    lg="6"
                    md="7"
                    className="d-block d-sm-none"
                    style={{
                    paddingRight: "10%",
                    paddingTop: "3%"
                }}>
                    <ContactForm/>
                </Col>
            </Row>
        </section>
        <section id="contact-details">
            <Row
                className="mb-1 contact-details-row"
                style={{
                paddingTop: "15px"
            }}>
                
                <Col
                    lg="4"
                    md="5"
                    style={{
                    paddingLeft: "5%"
                }}>
                    <Row
                        style={{
                        paddingBottom: "5%"
                    }}>
                        <h1
                            className="contact-h1"
                            style={{
                            color: "white",
                            fontSize: "48px"
                        }}>Get In Touch</h1>
                    </Row>
                    <div style={{
                        paddingLeft: "5%"
                    }}>
                        <FontAwesomeIcon
                            icon={faMapMarker}
                            size="2x"
                            style={{
                            color: "#AF7BAB",
                            float: "left"
                        }}/>
                        <p
                            style={{
                            color: "white",
                            fontSize: "18px",
                            marginBottom: "0",
                            paddingLeft: "50px"
                        }}>PsycApps, Health Foundry</p>
                        <p
                            style={{
                            color: "white",
                            fontSize: "18px",
                            marginBottom: "0",
                            paddingLeft: "50px"
                        }}>1 Royal Street,</p>
                        <p
                            style={{
                            color: "white",
                            fontSize: "18px",
                            marginBottom: "0",
                            paddingLeft: "50px"
                        }}>SE17LL London UK</p>
                    </div>

                    <div
                        style={{
                        paddingTop: "5%",
                        paddingLeft: "5%"
                    }}>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            size="2x"
                            style={{
                            color: "#AF7BAB",
                            float: "left"
                        }}/>
                        <p
                            style={{
                            color: "white",
                            fontSize: "18px",
                            marginBottom: "0",
                            paddingLeft: "20px",
                            float: "left"
                        }}>info@equoogame.com</p>
                    </div>
                </Col>
                <Col lg="2" className="d-none d-lg-block">
                    <img
                        src={joyStanding}
                        alt="Joy"
                        style={{
                        position: "relative",
                        width: "80%",
                        top: "-50px",
                        marginBottom: "-80px",
                        height: "auto"
                    }}></img>
                </Col>
                <Col
                    lg="6"
                    md="7"
                    className="d-none d-sm-block"
                    style={{
                    paddingRight: "10%"
                }}>
                    <ContactForm/>
                    
                </Col>
                
            </Row>

        </section>
        <section></section>
        <hr
            style={{
            height: "1px",
            backgroundColor: 'black',
            color: "black"
        }}/>
    </Layout>
)

export default ContactPage