import React from "react";

import {Modal} from 'react-bootstrap'

function EmailSentModal(props) {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Email Sent!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your message has been sent. Thank you! 
        </p>
      </Modal.Body>
    </Modal>
    );
  }

  export default EmailSentModal