import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

import {Form} from "react-bootstrap"

import sendBtn from '../images/buttons/send.png'

import EmailSentModal from './contact-modal';

const ContactForm = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hj5maq7', 'template_s9zspzl', e.target, 'user_gV301PtwhtwFBZYgKOu5F')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      setModalShow(true)
  };

  return (
    <Form ref={form} onSubmit={sendEmail}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control type="text" placeholder="Your name" name="from_name" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control type="text" placeholder="Subject" name="subject" required/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control type="email" placeholder="Your email address" name="user_email" required/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control as="textarea" placeholder="Your message" rows={3} name="message" required/>
        </Form.Group>
        <input style={{width: "70%"}} type="image" src={sendBtn} alt="Submit Form" />
        <p style={{color: "white", fontSize: "14px"}}>By pressing the button "SEND" I freely give consent to process my personal data for the purposes of contacting me. 
            I have the right to withdraw my data protection consent at any time with effect for the future. The withdrawal of 
            consent shall not affect the lawfulness of processing based on consent before its withdrawal. With my action I also 
            confirm that I have read and taken note of the <a style={{display: "inline-block", color: "#87CEEB"}} href="http://localhost:8000/disclaimer-tc/privacy-policy-dec-8-2020">Privacy Policy</a> and the <a style={{display: "inline-block", color: "#87CEEB"}} href="https://drive.google.com/file/d/1OMM-CwJDHrV35F-o8GvoWJh9rHtMapuB/view">Transparency Document</a>.</p>

        <div>
        <EmailSentModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
        </div>
    </Form>
  );
};

export default ContactForm